import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { withNamespaces } from "react-i18next";

import "../i18n";
import ContentLayout from "../components/contentLayout";

const ProjectsPage = ({ t, data }) => {
  return (
    <ContentLayout>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column center is-narrow">
              <h1 className="title is-2">{t("navbar:projects")}</h1>
            </div>
          </div>
          <div className="columns is-multiline contents-first">
            <div className="column is-one-third-desktop is-half-tablet center">
              <div className="card">
                <div className="card-image">
                  <Img
                    className="is-16by9"
                    fluid={data.image5.childImageSharp.fluid}
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <h3>
                      <a
                        href="https://github.com/veritable-tech/pytorch-lightning-spells"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PyTorch Lightning Spells
                      </a>
                    </h3>
                    <p>{t("lightning-spells-intro")}</p>
                    <p>#CV #NLP</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-one-third-desktop is-half-tablet center">
              <div className="card">
                <div className="card-image">
                  <Img
                    className="is-16by9"
                    fluid={data.image3.childImageSharp.fluid}
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <h3>
                      <Link to="/projects/youtube8m-2019">
                        {t("youtube8m")}
                      </Link>
                    </h3>
                    <p>{t("youtube8m-intro")}</p>
                    <p>#CV</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-one-third-desktop is-half-tablet center">
              <div className="card">
                <div className="card-image">
                  <Img
                    className="is-16by9"
                    fluid={data.image2.childImageSharp.fluid}
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <h3>
                      <Link to="/projects/reader">{t("reader")}</Link>
                    </h3>
                    <p>{t("reader-intro")}</p>
                    <p>#NLP</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-one-third-desktop is-half-tablet center">
              <div className="card">
                <div className="card-image">
                  <Img
                    className="is-16by9"
                    fluid={data.image1.childImageSharp.fluid}
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <h3>
                      <Link to="/projects/veritable-news">
                        {t("veritable-news")}
                      </Link>
                    </h3>
                    <p>{t("veritable-news-intro")}</p>
                    <p>#NLP</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-one-third-desktop is-half-tablet center">
              <div className="card">
                <div className="card-image">
                  <Img
                    className="is-16by9"
                    fluid={data.image4.childImageSharp.fluid}
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <h3>
                      <Link to="/projects/steadfast">Steadfast</Link>
                    </h3>
                    <p>{t("steadfast-intro")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContentLayout>
  );
};

export default withNamespaces(["projects", "navbar"], {})(ProjectsPage);

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projects-news-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "projects-reader-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "projects-yt8m-2019.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "steadfast-frontpage.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "lightning-spells-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
